export const searchTypeOptions = [
  { id: 'address', value: 'address', label: '住所から検索' },
  { id: 'parkingName', value: 'parkingName', label: '駐車場名から検索' },
];

// 支払いのステップ
export const PAYMENT_STEPS = {
  SELECT: 1,
  CONFIRM: 2,
  COMPLETE: 3,
  OUTDATE: 4,
  OVER_LIMIT: 5,
} as const;
export type PaymentStep = (typeof PAYMENT_STEPS)[keyof typeof PAYMENT_STEPS];

// 対象の駐車場で決済可能な最大範囲（半径[m]）
export const VALID_DISTANCE = 100;

// 出庫方法
export const PAYMENT_FLOWS = {
  CASH: 'cash',
  PASS: 'pass',
} as const;

export type PaymentFlow = (typeof PAYMENT_FLOWS)[keyof typeof PAYMENT_FLOWS];
